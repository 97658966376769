var rezStreamLoader = function (global) {
	var _callbacks = [];

	function loadScripts(options) {
		var min = getMinified(options.debug),
			filesToLoad = {},
			loadRequired = false,
		    jqueryPath = 'jquery/1.8.2/jquery' + min + '.js',
			jqueryUiPath = 'jqueryui/1.8.24/jquery-ui' + min + '.js';


		//Add jQuery reference (if needed)
		if (!hasJQuery()) {
			filesToLoad.jQuery = 'https://ajax.googleapis.com/ajax/libs/' + jqueryPath;
			loadRequired = true;
		}
		else {
			log('Using existing jQuery reference. Version: ' + global.jQuery().jquery + ' WARNING: jQuery v1.6 or higher is required for optimal experience.');
		}

		//Add jQuery UI reference (if needed)
		if (!hasJQueryUI(options)) {
			filesToLoad.jQueryUi = 'https://ajax.googleapis.com/ajax/libs/' + jqueryUiPath;
			loadRequired = true;
		}
		else {
			log('Using existing jQuery UI reference. Version: ' + global.jQuery.ui.version + ' WARNING: jQuery UI v1.8 or higher is required.');
		}

		var continueLoading = function () {
			if (hasJQuery()) {
				log('using jQuery v' + global.jQuery().jquery);
			}
			if (hasJQueryUI(options)) {
				log('using jQuery ui v' + global.jQuery.ui.version);
			}
			getLoaderFiles(options);
		};

		if (!loadRequired) {
			continueLoading();
			return;
		}

		yepnope.errorTimeout = 6000; //6 seconds
		yepnope({
			load: filesToLoad,
			callback: function (url, result, key) {
				//log('begin call back for: ' + key + ',hasJQuery: ' + hasJQuery() + ',hasJQueryUi: ' + hasJQueryUI(options));
				if (key === 'jQuery' && !hasJQuery()) {
					log('unable to load jQuery from CDN. reloading local.');
					yepnope(buildUrl('/cdn/content/js/jquery' + min + '.js'));
					return;
				}
				else if (key === 'jQueryUi' && !hasJQueryUI(options)) {
					log('unable to load jQuery UI from CDN. reloading local.');
					yepnope(buildUrl('/cdn/content/js/jquery-ui' + min + '.js'));
					return;
				}
			},
			complete: continueLoading
		});
	}

	function log(msg) {
		if (global.console) {
			global.console.log('RezStream: ' + msg);
		}
	}

	function hasJQuery() {
		return global.jQuery;
	}

	function hasJQueryUI(options) {
		if (!hasJQuery()) return false;

		var components = [],
			jq = global.jQuery;

		if (!jq.ui) {
			components.push('jQuery.ui');
		} else {
			if (!jq.ui.button) {
				components.push('jQuery.ui.button');
			}
		}
 
		if (!jq.widget) {
			components.push('jQuery.widget');
		}

		var jqUiLoaded = components.length === 0;
		if (!jqUiLoaded) {
			log('jQuery ui will be downloaded. Missing ui references: ' + components.join(','));
		}

		return jqUiLoaded;
	}

	function getLoaderFiles(options) {
		//load up the business/property theme
		var path = '/loaderOptions/' + getBizPropStr(options),
		    params = [];

		for (var i = 0; i < params.length; i++) {
			if (i === 0) {
				path += '?';
			}
			else {
				path += '&';
			}
			path += params[i];
		}

		global.jQuery.ajax({
			url: buildUrl(path),
			dataType: 'jsonp',
			cache: true,
			jsonpCallback: '_jqjspLoaderFiles',
			success: function (data) {
				//this function will return all the files that need to be loaded from our server
				var files = getFiles(options, data.files);
				yepnope({
					load: files,
					complete: function () {
						setupCalendars(options, data.env);
						executeCallbacks();
					}
				});
			}
		});
	}

	function getFiles(options, files) {
		if (!files) return {};
		if (!options.debug) return files;

		var nonMinFiles = {}
		for (var key in files) {
			if (files.hasOwnProperty(key)) {
				if (files[key].substr(files[key].length - 3) === '.js') {
					nonMinFiles[key] = files[key].replace(/.min/i, '');
				} else {
					nonMinFiles[key] = files[key];
				}
			}
		}
		return nonMinFiles;
	}

	function getBizPropStr(settings) {
		var bizSlug = settings.business || settings.businessSlug;

		return bizSlug.toString();
	}

	function buildUrl(path) {
		var host = ensureSuffix(getRezStreamRoot(), '/');
		path = stripPrefix(path, '/');
		return host + path;
	}

	function getRezStreamRoot() {
		if (global.RezStream && global.RezStream.beUrl) {
			return global.RezStream.beUrl;
		}
		return 'https://guest.rezstream.com/';
	}

	function ensureSuffix(value, suffix) {
		if (!value) return suffix;
		if (value.substr(value.length - 1) !== suffix) {
			return value + suffix;
		}
		return value;
	}

	function stripPrefix(value, prefix) {
		if (!value) return '';
		if (value.substr(0, 1) === prefix) {
			return value.substr(1);
		}
		return value;
	}

	function getMinified(debug) {
		return debug || document.location.protocol === 'file:' ? '' : '.min';
	}

	function setupCalendars(options, rezStreamSettings) {
		if (options.calElements) {
			global.RezStream = global.jQuery.extend(global.RezStream || {}, rezStreamSettings);
			global.RezStream.debug = options.debug;
			global.jQuery(options.calElements).siteCalendar(options);
		}
	}

	function addCallback(callbackFunction) {
		_callbacks.push(callbackFunction);
	}

	function executeCallbacks() {
		while (_callbacks.length > 0) {
			var callback = _callbacks.shift();
			if (callback) {
				callback.call(global.rezStreamLoader);
			}
		}
	}

	//Public interface
	return {
		load: function (options, onCompleteCallback) {
			addCallback(onCompleteCallback);
			loadScripts(options);
		},

		ready: function (onCompleteCallback) {
			addCallback(onCompleteCallback);
		},

		addCalendar: function (selector, options) {
			options.calElements = selector;
			setupCalendars(options);
		}
	};

}(window);
